//created by kris 2021/8/5
//any class name in the project should start with yph
@import '../../base.less'; 


.yph-login-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color:#EFFFFB;
    background-image: url("../../assets/images/yphbg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    .fztitle{
        width: 400px !important;
    }

    

    &-head{
        width: 100%;
        height: 40px;
        line-height: 44px;
        text-align: right;
    }

    &-content{
        width: 100%;
        padding: 5% 0px 24px;
        flex: 1 1 0%;

        &-qr-sub{
            color: @primary-color;
            margin-top: 24px;
            text-align: center;
        }

        &-description{
            text-align: center;
            color: @font-color-sub;
            margin: 20px auto 42px auto;

        }


        &-title{
            text-align: center;
            line-height: 54px;
            height: 54px;
            margin-bottom: 64px;
            &-main{
                font-size: 36px;
                margin-left: 24px;
                color: #000;
                
                
                
            }
        }



        &-form{
            &-subscription{
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: @primary-color;
                margin-bottom: 24px;
                
            }
        }

        &-title,&-form{
            width: 328px;
            margin: 0px auto;
        }

      


    }


}




@primary-color: #FF9967;